<template>
  <div class="verify">
    <part-title title="设备管理"></part-title>
    <div v-if="queryDeviceHandle" class="search-box">
        <span class="box-title">CIED型号：</span>
        <el-input size="medium" class="box-input" v-model="formData.modelName" placeholder="请输入CIED型号" >
        </el-input>
        <el-button type="primary" plain size="medium" @click="doSearch">查询</el-button>
    </div>
    <el-button v-if="addModelHandle" class="add-model-btn" type="primary" size="medium" @click="addModel">新增型号</el-button>
    <div class="table-box">
      <comp-table
        :table-opts="tableOpts"
        :data="tableData"
        :openHandle="true"
        :handle-opts="handleOpts"
        :page="currentPage"
        :total="totalPage"
        :page-size="pageSize"
        :showNumber="true"
        handle-width="92"
        @edit="doEdit"
        @delete="doDelete"
        @handleSizeChange="sizeChange"
        @handleCurrentChange="pageChange"></comp-table>
    </div>
    <el-drawer
      :title="`${isEditDrawer ? '编辑': '新增'}CIED型号信息`"
      :visible.sync="showDrawer"
      size="400px"
      :wrapperClosable="false"
      :close-on-press-escape="false"
      @close="handelCancel">
      <div style="margin: 24px">
        <el-form
          :model="ciedModelForm"
          :rules="rules"
          ref="ciedModelForm"
          label-width="74px"
          label-position="left">
          <el-form-item label="型号名称" prop="name">
            <el-input v-model="ciedModelForm.name" @input="checkValue"></el-input>
          </el-form-item>
          <el-form-item v-if="!isEditDrawer" label="所属品牌" prop="brandCode">
              <el-select v-model="ciedModelForm.brandCode" filterable allow-create placeholder="请选择" style="width:100%">
              <el-option
                v-for="item in ciedBrandList"
                :key="item.code"
                :label="item.name"
                :value="item.code">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="drawer-footer">
          <el-button class="footer-btn" @click="handelCancel">取消</el-button>
          <el-button class="footer-btn" type="primary" @click="handelAdd">{{`${isEditDrawer ? '保存': '新增'}`}}</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import PartTitle from '@/components/Part'
import CompTable from '@/components/Table/Table'
import { getModelList, addModel, editModel, deleteModel } from '@/service/module/deviceManage'
export default {
  components: {
    PartTitle,
    CompTable
  },
  data () {
    return {
      isEditDrawer: false,
      showDrawer: false,
      tableOpts: [
        {
          label: 'CIED型号',
          prop: 'modelName'
        },
        {
          label: '品牌中文名',
          prop: 'brandName'
        },
        {
          label: '品牌英文名',
          prop: 'brandEnName'
        },
        {
          label: '创建人',
          prop: 'creator'
        },
        {
          label: '创建时间',
          prop: 'createAt',
          width: '200px',
          render: (h, params) => {
            var moment = require('moment')
            const createAt = moment.unix(params.row.createAt / 1000).format('Y-MM-DD HH:mm:ss')

            const createAtText = '-'
            if (!params.row.createAt) {
              return h('span', {}, createAtText)
            } else {
              return h('span', {}, createAt)
            }
          }
        }
      ],
      handleOpts: {},
      tableData: [],
      configTable: [],
      totalPage: 0, // 总条数
      currentPage: 1, // 当前条数
      pageSize: 20, // 每页条数
      formData: {
        modelName: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入型号名称', trigger: 'blur' }
        ],
        brandCode: [
          { required: true, message: '请选择品牌名称', trigger: 'blur' }
        ]
      },
      ciedModelForm: {},
      ciedBrandList: [
        {
          code: '1',
          name: '美敦力'
        }, {
          code: '2',
          name: '雅培'
        }, {
          code: '4',
          name: '波科'
        }, {
          code: '3',
          name: '百多力'
        }

      ],

      addModelHandle: false,
      queryDeviceHandle: false
    }
  },
  mounted () {
    this.getList()
    const authList = JSON.parse(sessionStorage.getItem('auth')).perms
    authList.find(i => {
      if (i === 'device-mgr:add-model') {
        this.addModelHandle = true
      }
      if (i === 'device-mgr:edit-device') {
        this.handleOpts.edit = {
          type: 'text',
          text: '编辑'
        }
      }
      if (i === 'device-mgr:delete-device') {
        this.handleOpts.delete = {
          type: 'text',
          text: '删除'
        }
      }
      if (i === 'device-mgr:query-device') {
        this.queryDeviceHandle = true
      }
    })
  },
  methods: {
    async getList () {
      try {
        const res = await getModelList({
          pageNum: this.currentPage,
          pageSize: this.pageSize,
          modelName: this.formData.modelName
        })
        if (res && res.code === 200) {
          this.totalPage = res.data.total
          this.tableData = res.data.results
        } else {
          this.$message.error(res.message)
        }
      } catch (e) {
        this.$message.error(e.message)
      }
    },
    doSearch () {
      this.getList()
    },
    async doEdit (params) {
      this.showDrawer = true
      this.isEditDrawer = true
      this.ciedModelForm.name = params.modelName
      this.ciedModelForm.modelCode = params.modelCode
    },
    async doDelete (params) {
      this.$confirm('这信息删除后无法恢复，确定删除吗？', '确认删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteModel(params.modelCode).then(res => {
          if (res.code === 200) {
            this.$message.success('保存成功！')
            this.getList()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {
      })
    },
    sizeChange (val) {
      this.pageSize = val
      this.getList()
    },
    pageChange (val) {
      this.currentPage = val
      this.getList()
    },
    handelAdd () {
      this.$refs.ciedModelForm.validate((valid) => {
        if (valid) {
          if (!this.isEditDrawer) {
            addModel(this.ciedModelForm).then(res => {
              if (res.code === 200) {
                this.$message.success('保存成功!')
                this.showDrawer = false
                this.getList()
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            editModel(this.ciedModelForm).then(res => {
              if (res.code === 200) {
                this.$message.success('保存成功!')
                this.showDrawer = false
                this.getList()
              } else {
                this.$message.error(res.message)
              }
            })
          }
        }
      })
    },
    addModel () {
      this.ciedModelForm = {}
      this.showDrawer = true
      this.isEditDrawer = false
    },
    // 检查输入框内容是否包含特殊符号
    checkValue  () {
      this.$forceUpdate()
    },
    handelCancel () {
      this.$refs.ciedModelForm.resetFields()
      this.showDrawer = false
    }
  }
}
</script>

<style scoped lang="scss">
.verify {
  padding: 0 20px;
  .search-box {
    display: flex;
    justify-content: left;
    margin:15px 0 12px;
  }
}
::v-deep .el-form-item__label {
    padding-right: 0px;
}
.drawer-footer {
  position: fixed;
  bottom: 0;
  height: 60px;
  right: 20px;
}
::v-deep .el-button--text {
  font-size: 12px;
  font-weight: 400;
}
:v-deep .el-button--small {
  font-size: 14px;
}
.box-title {
  color: #606266;
  line-height: 36px;
  height: 36px;
}
.box-input {
  width: 168px;
  margin-right: 12px;
}
.add-model-btn {
  position: absolute;
  top: 45px;
  right: 20px;
}
::v-deep .el-drawer__header {
  font-weight: 500;
  font-size: 16px;
  color: #0F1114;
  padding: 16px 0px 16px 24px;
  margin-bottom: 0;
  box-shadow: inset 0px -1px 0px #E7E8EA;
}
</style>
