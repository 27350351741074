import { get, post } from '@http/axios'

// 分页查询型号列表
export const getModelList = (params) => get(`/api/doctor-platform/cied-device/model/list?pageNum=${params.pageNum}&pageSize=${params.pageSize}&modelName=${params.modelName}`)

// 新增型号
export const addModel = (params) => post('/api/doctor-platform/cied-device/model/add', params)

// 编辑型号
export const editModel = (params) => post('/api/doctor-platform/cied-device/model/edit', params)

// 删除型号
export const deleteModel = (params) => post(`/api/doctor-platform/cied-device/model/invalid/${params}`)
